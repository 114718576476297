export const NUM_FREE_DAILY_CREDITS = 10

export const PASSWORD_HASH_SALT = 'ac90d1b18189'

// Google's limit is 50,000 URLs per sitemap
export const URLS_PER_SITEMAP = 5000

export const SOFT_MAX_USER_PROMPT_TOKENS = 75
export const MAX_USER_PROMPT_TOKENS = 150
export const MAX_USER_NEGATIVE_PROMPT_TOKENS = 10

export const DataUpdated = {
  USER: 'USER',
  USER_MEMBERSHIP: 'USER_MEMBERSHIP',
  USER_CREDITS: 'USER_CREDITS',
  USER_IMAGE_GENERATION: 'USER_IMAGE_GENERATION',
} as const

export type DataUpdatedTypes = keyof typeof DataUpdated
