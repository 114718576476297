'use client'

import * as React from 'react'

interface Props {
  children?: React.ReactNode
}

export default function Snackbar({ children }: Props): React.ReactElement | null {
  return <div className="ml-2 flex max-w-screen-sm flex-col gap-2 text-xs sm:text-sm">{children}</div>
}
