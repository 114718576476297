import type { Organization } from 'schema-dts'
import {
  EnumImageGenerationStatus,
  EnumImageGuideType,
  EnumMembershipPeriod,
  EnumMembershipType,
  EnumStyle,
  EnumStyleType,
} from '../../../../../lib/database/lib/generated/enums'

export const CONTACT_US_TOPICS = ['General', 'Billing', 'Support', 'API']

export const IMAGE_GENERATION_STATUS_NAME: Record<keyof typeof EnumImageGenerationStatus, string> = {
  [EnumImageGenerationStatus.INITIALIZING]: 'Initializing',
  [EnumImageGenerationStatus.IN_QUEUE]: 'In queue',
  [EnumImageGenerationStatus.IN_PROGRESS]: 'In progress',
  [EnumImageGenerationStatus.CONVERTING_TO_SVG]: 'Converting to SVG',
  [EnumImageGenerationStatus.COMPLETED]: 'Completed',
  [EnumImageGenerationStatus.CANCELLED]: 'Cancelled',
  [EnumImageGenerationStatus.FAILED]: 'Failed',
  [EnumImageGenerationStatus.TIMED_OUT]: 'Timed out',
} as const

export const IMAGE_GUIDE_TYPE_NAME: Record<keyof typeof EnumImageGuideType, string> = {
  [EnumImageGuideType.EDGES]: 'Edges',
  [EnumImageGuideType.SOFT_EDGES]: 'Soft edges',
  [EnumImageGuideType.DEPTH]: 'Depth',
  // [EnumImageGuideType.POSE]: 'Pose',
  // [EnumImageGuideType.SEGMENTATION]: 'Segmentation',
} as const

export const MEMBERSHIP_PERIOD_NAME: Record<keyof typeof EnumMembershipPeriod, string> = {
  [EnumMembershipPeriod.MONTHLY]: 'Monthly',
  [EnumMembershipPeriod.YEARLY]: 'Yearly',
} as const

export const MEMBERSHIP_PERIOD_PERIOD_NAME: Record<keyof typeof EnumMembershipPeriod, string> = {
  [EnumMembershipPeriod.MONTHLY]: 'Month',
  [EnumMembershipPeriod.YEARLY]: 'Year',
} as const

export const MEMBERSHIP_TYPE_NAME: Record<keyof typeof EnumMembershipType, string> = {
  [EnumMembershipType.LITE]: 'Lite',
  [EnumMembershipType.PRO]: 'Pro',
  [EnumMembershipType.PREMIUM]: 'Premium',
  [EnumMembershipType.ENTERPRISE]: 'Enterprise',
} as const

export const REPORT_ISSUES = [
  'Copyright infringement',
  'Sexually explicit content',
  'Abusive content',
  'Violent content',
  'Illegal content',
  'Other - Please explain to us',
]

export const STYLE_TYPE_NAME: Record<keyof typeof EnumStyleType, string> = {
  [EnumStyleType.PIXELS]: 'Pixels',
  [EnumStyleType.SVG]: 'SVG',
}

export const STYLE_NAME: Record<keyof typeof EnumStyle, string> = {
  [EnumStyle.ABSTRACT]: 'Abstract',
  [EnumStyle.ALIEN]: 'Alien',
  [EnumStyle.ANALOG]: 'Analog',
  [EnumStyle.ANIMATED]: 'Animated',
  [EnumStyle.ANIME]: 'Anime',
  [EnumStyle.ARCHITECTURAL]: 'Architectural',
  [EnumStyle.BIOMECHANICAL]: 'Biomechanical',
  [EnumStyle.CARTOON]: 'Cartoon',
  [EnumStyle.CINEMATIC]: 'Cinematic',
  [EnumStyle.COLLAGE]: 'Collage',
  [EnumStyle.COLORING_BOOK]: 'Coloring book',
  [EnumStyle.COMIC_BOOK]: 'Comic book',
  [EnumStyle.CYBERNETIC]: 'Cybernetic',
  [EnumStyle.DIGITAL_ART]: 'Digital art',
  [EnumStyle.DISCO]: 'Disco',
  [EnumStyle.DREAMSCAPE]: 'Dreamscape',
  [EnumStyle.DRIPPING]: 'Dripping',
  [EnumStyle.DYSTOPIAN]: 'Dystopian',
  [EnumStyle.EXPRESSIONIST]: 'Expressionist',
  [EnumStyle.FANTASY]: 'Fantasy',
  [EnumStyle.FILM_NOIR]: 'Film noir',
  [EnumStyle.FLAT]: 'Flat',
  [EnumStyle.FOOD]: 'Food',
  [EnumStyle.FUTURISTIC]: 'Futuristic',
  [EnumStyle.GLAMOUR]: 'Glamour',
  [EnumStyle.GOTHIC]: 'Gothic',
  [EnumStyle.GRAFFITI]: 'Graffiti',
  [EnumStyle.HORROR]: 'Horror',
  [EnumStyle.ILLUSTRATION]: 'Illustration',
  [EnumStyle.IMPRESSIONIST]: 'Impressionist',
  [EnumStyle.ISOMETRIC]: 'Isometric',
  [EnumStyle.KIRIGAMI]: 'Kirigami',
  [EnumStyle.LINE_ART]: 'Line art',
  [EnumStyle.LONG_EXPOSURE]: 'Long exposure',
  [EnumStyle.LOWPOLY]: 'Lowpoly',
  [EnumStyle.MACABRE]: 'Macabre',
  [EnumStyle.MINECRAFT]: 'Minecraft',
  [EnumStyle.MONOCHROME]: 'Monochrome',
  [EnumStyle.NEON]: 'Neon',
  [EnumStyle.ORIGAMI]: 'Origami',
  [EnumStyle.PAPER_CUT]: 'Paper cut',
  [EnumStyle.PAPER_QUILL]: 'Paper quill',
  [EnumStyle.PHOTOGRAPHIC]: 'Photographic',
  [EnumStyle.PIXAR]: 'Pixar',
  [EnumStyle.PIXEL_ART]: 'Pixel art',
  [EnumStyle.POP_ART]: 'Pop art',
  [EnumStyle.PSYCHEDELIC]: 'Psychedelic',
  [EnumStyle.RENAISSANCE]: 'Renaissance',
  [EnumStyle.RETRO_ARCADE]: 'Retro arcade',
  [EnumStyle.SCI_FI]: 'Sci-fi',
  [EnumStyle.SHADOWBOX]: 'Shadowbox',
  [EnumStyle.SILHOUETTE]: 'Silhouette',
  [EnumStyle.SPACE]: 'Space',
  [EnumStyle.SPLATTER]: 'Splatter',
  [EnumStyle.STAINED_GLASS]: 'Stained glass',
  [EnumStyle.TRIBAL]: 'Tribal',
  [EnumStyle.TYPOGRAPHY]: 'Typography',
  [EnumStyle.WATERCOLOR]: 'Watercolor',
  [EnumStyle.NONE]: 'None',
} as const

export const STRIPE_PORTAL_MANAGE_SUBSCRIPTION_LINK =
  process.env['PICTRIX_STAGE'] === 'prod'
    ? 'https://billing.stripe.com/p/login/4gwbMO4DBaEaeas8ww'
    : 'https://billing.stripe.com/p/login/test_9AQ2aPh093vD3GUcMM'

export const schemaOrgPicTrixOrganization: Organization = {
  '@type': 'Organization',
  name: 'PicTrix.ai',
  alternateName: 'PicTrix AI',
  logo: {
    '@type': 'ImageObject',
    contentUrl: `${process.env['NEXT_PUBLIC_PICTRIX_WEBSITE_URL']}/images/logo.png`,
    width: '512 px',
    height: '130 px',
  },
  contactPoint: {
    '@type': 'ContactPoint',
    contactType: 'Customer Service',
    availableLanguage: ['English', 'French'],
    url: `${process.env['NEXT_PUBLIC_PICTRIX_WEBSITE_URL']}/contact`,
  },
  description: "Building high-quality modern AI tools to enhance professionals' creativity and productivity.",
  sameAs: [
    'https://www.facebook.com/pictrixai',
    'https://x.com/PicTrixAI',
    'https://www.instagram.com/pictrixai/',
    'https://www.pinterest.com/pictrixai',
    'https://www.linkedin.com/company/pictrixai',
  ],
}
